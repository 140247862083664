import React from 'react';


const ChargerSelection = () => {
  return (
    <div className="container mx-auto p-6">
      <h3 className="text-center text-2xl font-semibold mb-4">Choosing the Right EV Charger</h3>
      <div className="flex flex-wrap justify-center gap-4">
        <div className="card bg-white shadow-lg rounded-lg p-4 max-w-sm">
          <h4 className="text-xl font-bold mb-2">Level 1 Chargers</h4>
          <p className="text-gray-700 mb-4">Best for home use, slow charging.</p>
          <a href="https://www.naturaev.com/ev101" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300">
          More Info
        </a>
        </div>
        <div className="card bg-white shadow-lg rounded-lg p-4 max-w-sm">
          <h4 className="text-xl font-bold mb-2">Level 2 Chargers</h4>
          <p className="text-gray-700 mb-4">Ideal for faster home and public use.</p>
          <a href="https://www.naturaev.com/ev101" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300">
          More Info
        </a>
        </div>
        <div className="card bg-white shadow-lg rounded-lg p-4 max-w-sm">
          <h4 className="text-xl font-bold mb-2">DC Fast Chargers</h4>
          <p className="text-gray-700 mb-4">Commercial grade, super fast charging.</p>
          <a href="https://www.naturaev.com/ev101" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300">
          More Info
        </a>
        </div>
      </div>
    </div>
  );
};

export default ChargerSelection;
