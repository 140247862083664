import React from 'react';
import { Link, useParams } from 'react-router-dom';

// Define the Town interface
interface Town {
  name: string;
  image: string;
  path: string;
}

// Define the areas object using the Town interface
const areas: Record<string, Town[]> = {
  "Town of Huntington": [
    { name: "Centerport", image: "./assets/centerport.jpg", path: "/towns/centerport" },
    { name: "Cold Spring Harbor", image: "./assets/cold-spring-harbor.jpg", path: "/towns/cold-spring-harbor" },
    { name: "Commack", image: "./assets/commack-ny.jpg", path: "/towns/commack-ny" },
    { name: "East Northport", image: "./assets/east-northport.jpg", path: "/towns/east-northport" },
    { name: "Greenlawn", image: "./assets/greenlawn.webp", path: "/greenlawn" },
    { name: "Huntington", image: "./assets/huntington.jpg", path: "/huntington" },
    { name: "Huntington Station", image: "./assets/huntington-station.jpeg", path: "/huntington-station" },
    { name: "Lloyd Harbor", image: "./assets/lloyd-harbor.jpg", path: "/lloyd-harbor" },
    { name: "Melville", image: "./assets/melville.jpg", path: "/melville" },
    { name: "Northport", image: "./assets/northport.webp", path: "/northport" },
  ],
};

const TownPage = () => {
  let { townName } = useParams<{ townName: string }>();
  // Optionally use townName to fetch town details or handle it dynamically
  return (
    <div>
      <h1>{townName}</h1>
      {/* Additional logic to display town details */}
    </div>
  );
};

const ExploreNeighborhoods = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">Explore Long Island</h1>
      {Object.keys(areas).map((county) => (
        <div key={county} className="mb-5">
          <h2 className="text-2xl font-bold text-left mb-3">{county}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {areas[county].map((town) => (
              <div key={town.name} className="rounded overflow-hidden shadow-lg">
                <Link to={`/towns/${encodeURIComponent(town.name.replace(/\s+/g, '-').toUpperCase())}`}>
                  <img src={town.image} alt={town.name} className="w-full h-48 object-cover" />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">{town.name}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExploreNeighborhoods;
