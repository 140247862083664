// SmartEnergyLoan.tsx
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const SmartEnergyLoan: React.FC = () => {
  const { townName } = useParams<{ townName?: string }>(); // Ensure `townName` can be undefined
  // Decode the URL component and replace dashes with spaces
  const safeTownName = townName ? decodeURIComponent(townName).replace(/-/g, ' ') : 'Default Town';

  return (
    <div className="max-w-6xl mx-auto px-4 py-8 bg-white">
      <HelmetProvider>
        <title>Smart Energy Loan - Financing for Energy Upgrades ${safeTownName}</title>
        <meta name="description" content="Explore the Smart Energy Loan for financing the purchase and installation of energy-efficient systems like solar panels and heat pumps at your home." />
      </HelmetProvider>

      <h1 className="text-3xl font-bold text-center mb-6">Smart Energy Loan</h1>
      <p className="text-lg mb-4">
        The Smart Energy Loan offers a traditional financing method for your energy efficiency upgrades. Repayments are made monthly, either by check or automatic payment (ACH).
      </p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Technologies Covered</h2>
        <p className="text-lg">
          This loan supports the purchase and installation of various energy-saving technologies, including solar photovoltaic systems, ground source heat pumps, air source heat pumps, and other energy efficiency improvements.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Eligibility</h2>
        <p className="text-lg">
          To qualify for the Smart Energy Loan, you must either own the home or be an authorized representative of the property owner.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Loan Terms</h2>
        <p className="text-lg">
          These loans are unsecured, with interest rates subject to change. For the latest residential interest rates, please <a href="#" className="text-blue-500 hover:text-blue-600">click here</a> to find the interest rate.
        </p>
        <p className="text-lg">
          Loan amounts range from $1,500 to $25,000, with terms of 5, 10, or 15 years. It's important to note that if you sell or transfer the property, you remain responsible for the balance of the loan.
        </p>
      </section>
    </div>
  );
};

export default SmartEnergyLoan;
