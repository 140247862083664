// OptimizEV.tsx
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const OptimizEV: React.FC = () => {
  const { townName } = useParams<{ townName?: string }>(); // Ensure `townName` can be undefined
  // Decode the URL component and replace dashes with spaces
  const safeTownName = townName ? decodeURIComponent(townName).replace(/-/g, ' ') : 'Default Town';
  
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <HelmetProvider>
        <title>OptimizEV Program - Smart Charging and Rewards {safeTownName}</title>
        <meta name="description" content="Join the OptimizEV program to earn rewards by optimizing your EV charging routine. ${safeTownName} Shift your charging to off-peak times, enhance grid efficiency, and reduce energy costs." />
        <meta name="keywords" content="OptimizEV, EV charging, smart charging, EV rewards, energy efficiency, NYSEG, off-peak charging, EV incentives" />
      </HelmetProvider>

      <h1 className="text-2xl font-bold text-center mb-4">Charge Smart, Earn Rewards with OptimizEV</h1>
      <p className="text-lg mb-4">
        Join OptimizEV and earn incentives by optimizing your home EV charging routine to off-peak times, enhancing grid efficiency and reducing energy costs.
      </p>
      <h2 className="text-xl font-semibold mb-3">Participation Tiers</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>
          <strong>Baseline Tier:</strong> Earn a $25 one-time enrollment incentive and ongoing monthly incentives for shifting 80% of your charging to off-peak times.
        </li>
        <li>
          <strong>Advanced Tier:</strong> Get a $150 one-time enrollment incentive for scheduling smart charging sessions. Stick to your schedule to continue earning monthly benefits.
        </li>
      </ul>      
    </div>
  );
};

export default OptimizEV;
