import React from 'react';
import { civilWorkData } from '../Types/civilWorkData'

const CivilWorkSection: React.FC = () => {
    return (
        <div className="p-5 bg-white">
            <h1 className="text-xl font-bold">{civilWorkData.title}</h1>
            {civilWorkData.sections.map((section, index) => (
                <div key={index} className="mt-4">
                    <h2 className="text-lg font-semibold">{section.heading}</h2>
                    <ul className="list-disc list-inside">
                        {section.points.map((point, pointIndex) => (
                            <li key={pointIndex}>{point}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default CivilWorkSection;
