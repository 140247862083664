import React from "react";
import { Link } from "react-router-dom";

const NavBar: React.FC = () => {
  return (
    <nav className="bg-gray-800 text-white p-4 flex justify-between items-center">
      <Link to="/">
        <img src="/assets/gwhite.png" alt="Natura EV Logo" className="h-10" />
      </Link>
      <Link to="/" className="hidden md:block">
        <p>Natura EV</p> {/* Responsive visibility change */}
      </Link>
      <ul className="flex space-x-4">
        {/* Commented out to keep focus on changes */}
        {/* <li><a href="/about">About</a></li> */}
        <li><a href="/areas">Areas</a></li>
        <li><Link to="/rebates1">Rebates</Link></li> {/* Ensure correct path is used */}
        <li><a href="/ev101">EV101</a></li>
      </ul>
    </nav>
  );
};

export default NavBar;
