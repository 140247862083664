// RenewableEnergyTaxCreditBridgeLoan.tsx
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const RenewableEnergyTaxCreditBridgeLoan: React.FC = () => {
  const { townName } = useParams<{ townName?: string }>(); // Ensure `townName` can be undefined
  // Decode the URL component and replace dashes with spaces
  const safeTownName = townName ? decodeURIComponent(townName).replace(/-/g, ' ') : 'Default Town';
  
  return (
    <div className="max-w-6xl mx-auto px-4 py-8 bg-white">
      <HelmetProvider>
        <title>Renewable Energy Tax Credit Bridge Loan ${safeTownName}</title>
        <meta name="description" content="Finance your renewable energy investments with the Renewable Energy Tax Credit Bridge Loan. Learn about eligibility, loan terms, and how to maximize your tax credits." />
      </HelmetProvider>

      <h1 className="text-3xl font-bold text-center mb-6">Renewable Energy Tax Credit Bridge Loan</h1>
      <p className="text-lg mb-4">
        The Renewable Energy Tax Credit Bridge Loan (Bridge Loan) is a short-term loan designed to finance federal and state tax credits as well as NYC Real Property Tax Abatement for eligible renewable energy system products. This loan facilitates immediate investment in sustainable technologies by covering upfront costs.
      </p>

      <div className="p-6 border-l-4 border-green-500 bg-green-100 mb-6">
        <h2 className="text-2xl font-semibold mb-3">Key Features of the Bridge Loan</h2>
        <ul className="list-disc pl-5 mb-4 text-lg">
          <li>Enables financing of federal and state tax credits and NYC Real Property Tax Abatement.</li>
          <li>Balloon payment of principal and interest due at maturity, payable via check or ACH.</li>
          <li>Can be paired with a Smart Energy or On-Bill Recovery Loan (total not to exceed $25,000).</li>
        </ul>
        <p className="text-lg">
          Consult with your contractor for assistance with application procedures.
        </p>
      </div>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Technologies Covered</h2>
        <p className="text-lg">
          The loan supports the purchase and installation of solar photovoltaic systems, air and ground source heat pumps, and other energy efficiency improvements.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Eligibility and Loan Terms</h2>
        <p className="text-lg">
          This unsecured loan is available to homeowners or authorized representatives of the property owner. Interest rates are subject to change, and interest accrues from loan origination.
        </p>
        <p className="text-lg">
          Loan amounts range from $1,500 to $25,000 with a term of 2 years. If you sell or transfer the property, you remain responsible for the balance of the loan.
        </p>
        <p className="text-lg">
          <a href="#" className="text-blue-500 hover:text-blue-600">Find current interest rates.</a>
        </p>
      </section>
    </div>
  );
};

export default RenewableEnergyTaxCreditBridgeLoan;
