// MakeReadyProgram.tsx
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const MakeReadyProgram: React.FC = () => {
  const { townName } = useParams<{ townName?: string }>(); // Ensure `townName` can be undefined
    // Decode the URL component and replace dashes with spaces
    const safeTownName = townName ? decodeURIComponent(townName).replace(/-/g, ' ') : 'Default Town';

  return (
    <HelmetProvider>
      <div className="max-w-5xl mx-auto p-8 bg-white shadow-lg rounded-lg">
        <Helmet>
          <title>Make-Ready Program for EV Charging Stations {safeTownName}</title>
          <meta name="description" content="Discover the Make-Ready Program which provides significant incentives for businesses to install EV charging stations. Learn how to become an approved contractor and start installations with up to 100% cost reimbursement." />
        </Helmet>

        <h1 className="text-3xl font-bold text-center mb-6">EV Charging Station Installations - Make-Ready Program {safeTownName}</h1>
        
        <p className="text-lg mb-4">
          Transition to electric, zero-emission transportation with our Make-Ready Program, designed to make EV charging station installations simple and budget-friendly. Businesses can receive up to 100% reimbursement for the necessary electrical improvements.
        </p>

        <h2 className="text-2xl font-semibold mb-3">Interested in Getting Your Own EV Charging Stations in {safeTownName}?</h2>
        <p className="text-lg mb-4">
          EV charging stations can enhance your business's sustainability profile, attract new customers, and generate additional revenue. Learn more about how you can get started with the Make-Ready Program.
        </p>

        <h2 className="text-2xl font-semibold mb-3">For Contractors and Developers in {safeTownName}</h2>
        <p className="text-lg mb-4">
          Join the network and benefit from the growing demand for EV charging installations. Approved contractors are eligible for Make-Ready incentives. If you are not yet approved, apply to become an approved contractor today.
        </p>
        </div>
    </HelmetProvider>
  );
};

export default MakeReadyProgram;
