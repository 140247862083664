// Rebates.tsx
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Nyserda from '../FinancialResidential/Nyserda';
import NYSERDAFinancing from '../FinancialResidential/NyserdaFinancing';
import SmartEnergyLoan from '../FinancialResidential/SmartEnergyLoan';
import RenewableEnergyTaxCreditBridgeLoan from '../FinancialResidential/RenewableEnergyTaxCreditBridgeLoan';
import OptimizEV from '../FinancialResidential/OptimizEV';
import PSEGChargerRebate from '../FinancialResidential/PSEGChargerRebates';
import MakeReadyProgram from '../FinancialResidential/MakeReadyProgram';

const Rebates1: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto p-8">
      <HelmetProvider>
        <title>Rebates and Incentives Programs</title>
        <meta name="description" content="Explore various rebates and incentives programs offered for energy efficiency and renewable energy solutions in New York State." />
      </HelmetProvider>
      <h1 className="text-3xl font-bold text-center mb-6">Rebates and Incentives</h1>
      <Nyserda />
      <NYSERDAFinancing />
      <SmartEnergyLoan />
      <RenewableEnergyTaxCreditBridgeLoan />
      <OptimizEV />
      <PSEGChargerRebate />
      <MakeReadyProgram />
    </div>
  );
};

export default Rebates1;
