// NYSERDAFinancing.tsx
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const NYSERDAFinancing: React.FC = () => {
  const { townName } = useParams<{ townName?: string }>(); // Ensure `townName` can be undefined
    // Decode the URL component and replace dashes with spaces
    const safeTownName = townName ? decodeURIComponent(townName).replace(/-/g, ' ') : 'Default Town';

  return (
    <div className="max-w-6xl mx-auto px-4 py-8 bg-white">
      <HelmetProvider>
        <title>NYSERDA Residential Financing Programs for Energy Upgrades {safeTownName}</title>
        <meta name="description" content="Learn about NYSERDA's financing options for residential energy upgrades including loans for solar panels, heat pumps, and more in New York State." />
      </HelmetProvider>

      <h1 className="text-3xl font-bold text-center mb-6">NYSERDA Residential Financing Programs {safeTownName}</h1>
      <p className="text-lg mb-4">
        Home energy upgrades like heat pumps, solar panels, and improved insulation can significantly reduce energy costs. Many homeowners combine rebates, tax credits from the Inflation Reduction Act, and financing to cover these upgrades.
      </p>
      <p className="text-lg mb-4">
        NYSERDA offers a variety of loans to finance energy efficiency improvements and clean energy equipment for New York State residents.
      </p>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">{safeTownName} :Before Applying for a Loan</h2>
        <p className="text-lg">
          Homeowners must complete a no-cost energy assessment, providing an overview of home energy usage and identifying energy-saving opportunities. Loans can be applied towards a variety of energy upgrades through programs such as EmPower+, Comfort Home Program, NY-Sun, and NYS Clean Heat.
        </p>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Financing Options {safeTownName}</h2>
        <p className="text-lg">
          Homeowners may be eligible for up to $25,000 in loans for energy improvements at residential properties. Interest rates and repayment terms vary by loan type. Notably, loans over $13,000 require a payback period of less than 15 years as mandated by the Green Jobs Green New York legislation.
        </p>
        <p className="text-lg">
          Work with a participating contractor to select the best loan for your home energy project.
        </p>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Loan Types</h2>
        <p className="text-lg">
          <strong>On-Bill Recovery Loan:</strong> Payments are made as part of your utility bill, ensuring that monthly energy cost savings are greater than the loan payments.
        </p>
        <div className="p-6 border-l-4 border-green-500 bg-green-100">
          <h3 className="text-xl font-semibold mb-2">On-Bill Recovery Loan Features: {safeTownName}</h3>
          <ul className="list-disc pl-5 mb-4">
            <li>Loan payments are integrated into your utility bill, simplifying repayment.</li>
            <li>Covered technologies include solar photovoltaic systems, ground and air source heat pumps, and energy efficiency upgrades.</li>
            <li>Eligibility requires being a homeowner and named on the utility account.</li>
            <li>Loan amounts range from $1,500 to $25,000 with terms of 5, 10, or 15 years.</li>
            <li>The balance can be transferred to a new owner if the home is sold.</li>
          </ul>
          <p className="text-lg">For more detailed information on rates and eligibility, homeowners are encouraged to consult directly with NYSERDA-approved contractors or through their utility providers.</p>
        </div>
      </section>
    </div>
  );
};

export default NYSERDAFinancing;
