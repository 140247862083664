import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Nyserda from '../FinancialResidential/Nyserda';
import NYSERDAFinancing from '../FinancialResidential/NyserdaFinancing';
import SmartEnergyLoan from '../FinancialResidential/SmartEnergyLoan';
import RenewableEnergyTaxCreditBridgeLoan from '../FinancialResidential/RenewableEnergyTaxCreditBridgeLoan';
import OptimizEV from '../FinancialResidential/OptimizEV';
import PSEGChargerRebate from '../FinancialResidential/PSEGChargerRebates';
import MakeReadyProgram from '../FinancialResidential/MakeReadyProgram';

const Centerport = () => {
    const { townName } = useParams<{ townName?: string }>(); // Ensure `townName` can be undefined
    // Decode the URL component and replace dashes with spaces
    const safeTownName = townName ? decodeURIComponent(townName).replace(/-/g, ' ') : 'Default Town';

    
    return (
        <div className="max-w-6xl mx-auto px-4 py-8">
            <HelmetProvider>
                <Helmet>
                    <meta name="description" content={`Explore top EV charging solutions in ${safeTownName} including Level 1, 2, 3 chargers. Find out about rebates, incentives, and comprehensive installation services for both residential and commercial applications.`} />
                    <meta name="keywords" content={`EV charging, ${safeTownName}, Level 1 chargers, Level 2 chargers, Level 3 chargers, EV rebates, EV incentives, EV charger installation, residential EV charging, commercial EV charging, EV charger grants, EV software`} />
                </Helmet>
            </HelmetProvider>
            <h1 className="text-4xl font-bold text-center mb-6">EV Charging Solutions in {safeTownName}</h1>
            <h1 className="text-3xl font-bold mb-6">EV Charging Solutions in {safeTownName}</h1>
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Choosing Your EV Charger</h2>
                <p className="text-lg">Determine the best EV charger based on your vehicle type, charging speed requirements, and installation considerations. Learn the differences between Level 1, Level 2, and DC Fast Chargers (Level 3).</p>
            </section>
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Rebates, Incentives, and Grants</h2>
                <p className="text-lg">Discover available local and federal incentives that make installing an EV charger more affordable. These include tax credits, rebates, and grants specific to {safeTownName} residents and businesses.</p>
            </section>
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Installation and Electrical Work</h2>
                <p className="text-lg">Professional installation services from certified technicians ensure safe and efficient charger setup. We cover electrical assessments, upgrades, and full installation of all types of EV chargers.</p>
            </section>
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Software for EV Chargers</h2>
                <p className="text-lg">Enhance your charging experience with smart software solutions that allow remote management, scheduling, and monitoring of your charging sessions.</p>
            </section>
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Residential and Commercial Solutions</h2>
                <p className="text-lg">Whether you need a home-based charging station or a comprehensive setup for your business fleet, find out how our tailored solutions can meet your needs.</p>
            </section>

            <h1 className="text-3xl font-bold text-center mb-6">Installing an EV Charger at Home</h1>
            
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Why Install an EV Charger at Home?</h2>
                <p className="text-lg mb-4">
                Having an EV charger at home significantly enhances the convenience of owning an electric vehicle. It allows for overnight charging, ensuring your vehicle is ready each morning, reducing reliance on public charging stations. Home chargers also offer faster charging speeds compared to standard outlets, making them an essential investment for every electric vehicle owner.
                </p>
                <p className="text-lg">
                Installation at home can be straightforward if planned correctly. This guide will explore the necessary steps, the benefits of installation, and how to maximize the utility of your own home charging station.
                </p>
            </section>
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Choosing the Right EV Charger</h2>
                <p className="text-lg mb-4">
                Selecting the right charger depends on your vehicle's requirements and your charging habits. Level 1 chargers are standard but slow, while Level 2 chargers offer faster charging speeds suitable for those with regular driving needs. This section will detail the pros and cons of each type and what features to look for when purchasing a charger.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Installation Process</h2>
                <p className="text-lg mb-4">
                The installation process involves several steps, starting from electrical assessments to the actual physical installation. You'll need to understand the electrical requirements, find a suitable location, and possibly upgrade your home's electrical system. Hiring a professional electrician is highly recommended to ensure safety and compliance with local regulations.
                </p>
            </section>
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Costs and Incentives</h2>
                <p className="text-lg">
                Costs can vary based on the type of charger and installation complexity. However, many regions offer incentives such as rebates, tax credits, and grants to offset these costs. This section will provide an overview of potential savings and how to apply for these incentives.
                </p>
            </section>
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">FAQs and Common Concerns</h2>
                <p className="text-lg">
                Address common questions and concerns such as the time required for installation, whether you can install a charger yourself, and what to do if you have more than one EV. This FAQ will help clarify any uncertainties and ensure you are fully prepared to take the next steps.
                </p>
            </section>

            <Nyserda />
            <NYSERDAFinancing />
            <SmartEnergyLoan />
            <RenewableEnergyTaxCreditBridgeLoan />
            <OptimizEV />
            <PSEGChargerRebate />
            <MakeReadyProgram />   
            
            </div>
    );
};

export default Centerport;
