import React from 'react';
import { Link } from 'react-router-dom';
import Locations from './Locations';

const neighborhoods = [
    { name: "Long Island", image: "./assets/LI.jpg", path: "/brooklyn" },
    { name: "NYC", image: "./assets/nyc.webp", path: "/queens" },
    { name: "New Jersey", image: "./assets/nj.webp", path: "/manhattan" },
    { name: "Delaware", image: "./assets/delaware.jpg", path: "/staten-island" },
    { name: "Philadelphia", image: "./assets/philly.jpg", path: "/the-bronx" }
  ];
  

const ExploreNeighborhoods2 = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">Explore our Neighborhoods</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {neighborhoods.map((neighborhood) => (
          <div key={neighborhood.name} className="relative">
            <Link to={neighborhood.path}>
              <img src={neighborhood.image} alt={neighborhood.name} className="w-full h-64 object-cover rounded-lg shadow-lg" />
              <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-lg font-bold p-4">{neighborhood.name}</div>
            </Link>
          </div>
        ))}
      </div>
      <Locations />
    </div>
  );
};

export default ExploreNeighborhoods2;
