// EVChargerInstallation.tsx (Updated with NYSERDA section)
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';



const Nyserda: React.FC = () => {
  const { townName } = useParams<{ townName?: string }>(); // Ensure `townName` can be undefined
    // Decode the URL component and replace dashes with spaces
    const safeTownName = townName ? decodeURIComponent(townName).replace(/-/g, ' ') : 'Default Town';

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <HelmetProvider>
        <title>Comprehensive Guide to EV Charger Installation and Financing in {safeTownName}</title>
        <meta name="description" content="Explore top EV charging solutions in ${safeTownName}, including Level 1, 2, 3 chargers and learn about NYSERDA financing for energy efficiency upgrades." />
      </HelmetProvider>

      <h1 className="text-3xl font-bold text-center mb-6">EV Charging Solutions and Financing in {safeTownName}</h1>
      
      {/* Existing sections here... */}

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">NYSERDA Financing for Home Energy Upgrades in {safeTownName}</h2>
        <p className="text-lg mb-4">
          Enhancing your home with energy-efficient upgrades like heat pumps, solar panels, and improved insulation can significantly reduce your energy costs. To make these upgrades more accessible, NYSERDA offers a suite of loans to help New York State residents finance these improvements effectively.
        </p>
        <p className="text-lg mb-4">
          Before accessing a NYSERDA loan, homeowners are required to complete a no-cost energy assessment, which provides a detailed overview of your home’s energy usage and pinpoints potential energy-saving opportunities. These loans can be utilized for various energy upgrades through programs such as EmPower+, Comfort Home Program, NY-Sun, NYS Clean Heat, and others.
        </p>
        <p className="text-lg mb-4">
          Homeowners could qualify for up to $25,000 in loans for energy efficiency improvements or clean energy equipment installation at one- to four-family residential properties. It's important to note that loans are not grants and must be repaid; however, they are structured with favorable terms to ensure cost-effectiveness over time.
        </p>
      </section>

      {/* More sections as necessary... */}
    </div>
  );
};

export default Nyserda;
