// PSEGChargerRebate.tsx
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const PSEGChargerRebate: React.FC = () => {
  const { townName } = useParams<{ townName?: string }>(); // Ensure `townName` can be undefined
  // Decode the URL component and replace dashes with spaces
  const safeTownName = townName ? decodeURIComponent(townName).replace(/-/g, ' ') : 'Default Town';

  return (
    <HelmetProvider>
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
        <Helmet>
          <title>PSEG Long Island Residential Charger Rebate Program ${safeTownName}</title>
          <meta name="description" content="Learn about the PSEG Long Island Residential Charger Rebate Program that offers up to $300 in rebates for installing ENERGY STAR Certified Level 2 EV chargers at home in ${safeTownName}. Find out how you can qualify for these rebates and support your transition to electric vehicle ownership." />
          <meta name="keywords" content="PSEG Long Island, EV charger rebate, ENERGY STAR chargers, residential EV charging, EV rebate program, Disadvantaged Communities, DAC, electric vehicle" />
        </Helmet>

        <h1 className="text-3xl font-bold text-center mb-6">PSEG Long Island Residential Charger Rebate Program</h1>
        <p className="text-lg mb-4">
          The PSEG Long Island Residential Charger Rebate Program encourages homeowners to install energy-efficient EV chargers by providing significant financial incentives.
        </p>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">Rebate Details</h2>
          <p className="text-lg">
            This program provides a $200 rebate for the installation of qualified ENERGY STAR® Certified Level 2 EV chargers. For residents in Disadvantaged Communities (DAC), an additional $100 is available, allowing for a total rebate of up to $300.
          </p>
          <p className="text-lg mb-4">
            The additional financial support for DAC residents aims to make sustainable technology more accessible and help reduce overall energy costs in these communities.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">Eligibility Criteria</h2>
          <p className="text-lg">
            To qualify for the rebate, applicants must install an ENERGY STAR Certified Level 2 charger at a residential property located in Long Island. The additional $100 rebate requires the property to be situated within a designated Disadvantaged Community.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">How to Apply</h2>
          <p className="text-lg">
            Applications can be submitted through the PSEG Long Island web portal. Documentation of the ENERGY STAR certification and proof of residence in a DAC (if applicable) must be provided during the application process.
          </p>
          <div className="mt-4 flex justify-center">
            <a href="#" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Apply Now</a>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default PSEGChargerRebate;
