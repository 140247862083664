import React from 'react';
import Locations from '../Locations';
import ContentHero from './ContentHero';
import ExploreNeighborhoods from './ExploreNeighborhoods';
import ChargerSelection from './ChargerSelection';
import WelcomeSection from './WelcomeSection';

const Home = () => {
  return (
    <div>
      <WelcomeSection />
      <ChargerSelection />
      <ExploreNeighborhoods />
      <ContentHero />
      {/* Add more components that should appear on the home page */}
    </div>
  );
};

export default Home;
